import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {saveToken} from "../../services/GenerateTokenService";
import {useNavigate} from "react-router";
import {useDispatch} from "react-redux";

function FacebookAuthorize() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(function effectFunction() {
    dispatch(saveToken(searchParams.get("code"))).then(() => navigate("/generate"))
  }, [navigate, searchParams, dispatch])

  return (
      <p/>
  )
}

export default FacebookAuthorize