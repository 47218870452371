import React from "react";
import PropTypes from "prop-types";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const NotificationBar = ({notification, hideNotification, dequeueNotification}) => {
  const {isOpen, queue} = notification;
  const {message, variant} = queue.length > 0 ? queue[0] : {};
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = () => {
    hideNotification();
    setTimeout(() => {
      dequeueNotification();
    }, 500);
  };

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      resumeHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

NotificationBar.prototype = {
  notification: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    queue: PropTypes.array.isRequired
  }),
  hideNotification: PropTypes.func.isRequired,
  dequeueNotification: PropTypes.func.isRequired
}

export default NotificationBar