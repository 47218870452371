import { connect } from 'react-redux';
import Loading from "../../components/Loading";

const mapStateToProps = state => ({
  requestStatus: state.requestStatusReducer
});

export default connect(
  mapStateToProps
)(Loading);
