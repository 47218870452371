import {Navigate, useLocation, useNavigate} from "react-router";
import {authenticate, logout, requestAuthentication} from "./services/AuthService";
import React from "react";

export const AuthContext = React.createContext(null);

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export const AuthProvider = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = React.useState("init");
  const [loginFailed, setLoginFailed] = React.useState(false);

  React.useEffect(function effectFunction() {
    authenticate()
      .then(email => {
        setEmail(email)
        if (email !== null) {
          if (location.pathname === "/") {
            navigate("/download")
          }
        }
      })
  }, [location, navigate]);

  const handleLogin = async (googleData) => {
    const response = await requestAuthentication(googleData);
    if (response.status === 200) {
      const responseBody = await response.json()
      if (responseBody !== null) {
        setEmail(responseBody.result);
      }
      const origin = location.state?.from?.pathname || '/download';
      navigate(origin);
    } else {
      setLoginFailed(true)
    }
  };

  const handleLogout = async () => {
    await window.google.accounts.id.revoke();
    await logout();
    setEmail(null);
  };

  const value = {
    email,
    onLogin: handleLogin,
    onLogout: handleLogout,
    loginFailed: loginFailed
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const ProtectedRoute = ({children}) => {
  const {email} = useAuth();
  const location = useLocation();

  if (!email) {
    return <Navigate to="/" replace state={{from: location}}/>;
  }

  return children;
}