import {Spinner} from "react-bootstrap";
import './index.css'

const Loading = ({requestStatus}) => {
  const { isLoading } = requestStatus
  if(isLoading) {
    return (
      <div className="root">
        <Spinner animation="border" role="status" className="progress">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  } else {
    return null
  }
}

export default Loading