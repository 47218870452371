import React, {useEffect, useState} from "react";
import "./index.css";
import Header from "../Header";
import {getInfo} from "../../services/GenerateTokenService";
import Alert from "react-bootstrap/Alert";
import {useDispatch} from "react-redux";

function GenerateToken() {
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID
  const url = process.env.REACT_APP_URL
  const facebookVersion = process.env.REACT_APP_FACEBOOK_GRAPH_API_VERSION
  const codeRequestUrl = `https://www.facebook.com/${facebookVersion}/dialog/oauth?` +
    `client_id=${appId}` +
    `&redirect_uri=${url}/facebook/authorize` +
    "&scope=public_profile,ads_management,ads_read"
  const [info, setInfo] = useState();
  const [validToken, setValidToken] = useState(true);
  const dispatch = useDispatch();

  useEffect(
    function effectFunction() {
      dispatch(getInfo()).then(data => {
        if (data) setInfo(data)
        else setValidToken(false)
      })
    }, [dispatch]
  );

  return (
    <div>
      <Header/>
      <div className="generate-token">
        <div className="generate-alert">
          <h4 className="generate-title">{validToken ? '以下のFacebookユーザで接続されています' : 'アクセストークンの生成をお願いします'}</h4>
          <h5 className="generate-title">※管理者以外は編集禁止</h5>
          {validToken ?
            (<h6 className="token-info">Facebookユーザ名： {info}</h6>) :
            (<Alert variant="danger" className="errorMessage">
              アクセストークンが無効です。
            </Alert>)
          }
          <a className="btn btn-download" href={codeRequestUrl}>トークンを生成する</a>
        </div>
      </div>
    </div>
  );
}

export default GenerateToken;
