import * as actionTypes from './actionTypes'

// define basic message or variant.
export const NOTIFICATION_VARIANT_ERROR = 'error'
export const NOTIFICATION_VARIANT_SUCCESS = 'success'
export const NOTIFICATION_MESSAGE_GET_DATA_SUCCESS = 'データの取得が成功しました'
export const NOTIFICATION_MESSAGE_GET_DATA_FAILED = 'データの取得が失敗しました'
export const NOTIFICATION_MESSAGE_GENERATE_SUCCESS = 'アクセストークンの発行が成功しました'
export const NOTIFICATION_MESSAGE_GENERATE_FAILED = 'アクセストークンの発行が失敗しました'

export const enqueueGetDataSuccessNotification = (
  message = NOTIFICATION_MESSAGE_GET_DATA_SUCCESS,
  variant = NOTIFICATION_VARIANT_SUCCESS
) => {
  return enqueueNotification(message, variant);
};

export const enqueueGetDataFailedNotification = (
  message = NOTIFICATION_MESSAGE_GET_DATA_FAILED,
  variant = NOTIFICATION_VARIANT_ERROR
) => {
  return enqueueNotification(message, variant);
};

export const enqueueGenerateSuccessNotification = (
  message = NOTIFICATION_MESSAGE_GENERATE_SUCCESS,
  variant = NOTIFICATION_VARIANT_SUCCESS
) => {
  return enqueueNotification(message, variant);
};

export const enqueueGenerateFailedNotification = (
  message = NOTIFICATION_MESSAGE_GENERATE_FAILED,
  variant = NOTIFICATION_VARIANT_ERROR
) => {
  return enqueueNotification(message, variant);
};

export const enqueueNotification = (message, variant) => ({
  type: actionTypes.NOTIFICATION_ENQUEUE,
  payload: {
    message: message,
    variant: variant
  }
})

export const dequeueNotification = () => ({
  type: actionTypes.NOTIFICATION_DEQUEUE,
  payload: {}
})

export const hideNotification = () => ({
  type: actionTypes.NOTIFICATION_HIDE,
  payload: {}
})
