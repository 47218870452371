import * as actionTypes from './actionTypes';

export const genRequestLoadingAction = () => ({
  type: actionTypes.UPDATE_REQUEST_STATUS,
  payload: {
    isLoading: true
  }
});

export const genRequestFinishAction = () => ({
  type: actionTypes.UPDATE_REQUEST_STATUS,
  payload: {
    isLoading: false
  }
});

/**
 * redirect to top, if not authenticated.
 */
export const commonErrorHandler = (status) => {
  if (status === 401) {
    window.location.href = '/';
  }
};
