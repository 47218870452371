import React from "react";
import {Route, Routes} from "react-router";
import Download from "./components/Download";
import GenerateToken from "./components/GenerateToken";
import Login from "./components/Login";
import {AuthProvider, ProtectedRoute} from "./AuthProvider";
import FacebookAuthorize from "./components/FacebookAuthorize";
import NotificationBar from "./redux/containers/notificationBar";
import Loading from "./redux/containers/loading";

const App = () => {
  return (
    <AuthProvider>
      <NotificationBar/>
      <Loading/>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route
          path="/download"
          element={
            <ProtectedRoute>
              <Download/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/generate"
          element={
            <ProtectedRoute>
              <GenerateToken/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/facebook/authorize"
          element={
            <ProtectedRoute>
              <FacebookAuthorize/>
            </ProtectedRoute>
          }
        />
      </Routes>
    </AuthProvider>
  );
};

export default App;
