import {request} from "./callAPI";
import {genRequestFinishAction, genRequestLoadingAction} from "../redux/actions/requestStatusActions";
import {
  enqueueGenerateFailedNotification,
  enqueueGenerateSuccessNotification
} from "../redux/actions/notificationActions";

export const saveToken = (code) => {
  return async dispatch => {
    try {
      dispatch(genRequestLoadingAction())
      await request.get(`/access_token/save?code=${code}`)
      dispatch(genRequestFinishAction())
      dispatch(enqueueGenerateSuccessNotification())
    } catch(error) {
      dispatch(genRequestFinishAction())
      dispatch(enqueueGenerateFailedNotification())
    }
  }
}

export const getInfo = () => {
  return async dispatch => {
    try {
      dispatch(genRequestLoadingAction())
      const result = await request.get(`/access_token/info`)
      dispatch(genRequestFinishAction())
      return result.data.name
    } catch(error) {
      dispatch(genRequestFinishAction())
      return null
    }
  }
}

