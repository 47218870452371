import { UPDATE_REQUEST_STATUS } from '../actions/actionTypes';

export const requestStatusReducer = (
  state = {
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case UPDATE_REQUEST_STATUS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
