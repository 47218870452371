import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { useNavigate } from "react-router";
import Select from "react-select";
import downloadIcon from "../../assets/download.png";
import {
  requestAdAccount,
  requestDownload,
} from "../../services/DownloadService";
import Header from "../Header";
import "./index.css";
import { useDispatch } from "react-redux";

const Download = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [accountSelected, setAccountSelected] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState([]);
  const [invalidToken, setInvalidToken] = useState(false);

  const handleChange = (e) => {
    if(!e){
      setAccountSelected(false)
    }else{
      setSelectedAccountId(e.value);
      setAccountSelected(true);
    }  
  };

  useEffect(
    function effectFunction() {
      dispatch(requestAdAccount()).then((data) => {
        if (data) setData(data);
        else setInvalidToken(true);
      });
    },
    [navigate, dispatch]
  );

  const downloadCampaign = async () => {
    await dispatch(requestDownload(selectedAccountId));
  };

  return (
    <div>
      <Header />
      <div className="download-page">
        <h4 className="header-download">広告アカウントのリスト</h4>
        <Select
          className="select-ad"
          onChange={handleChange}
          value={data.find((obj) => obj.value === selectedAccountId )}
          options={data.map((item) => ({
            value: item.id,
            label: item.id + " - " + item.name,
          }))}
          // options={options}
          isClearable
          placeholder="対象アカウントを選択してください"
          noOptionsMessage={() => 'データが見つかりません!'}
        />
        {invalidToken && (
          <Alert variant="danger" className="errorMessage">
            アクセストークンが無効です。 アクセストークンを生成するには、
            <a href="/generate"> GENERATEのページ </a>にアクセスしてください。
          </Alert>
        )}
        <Button
          className="btn"
          disabled={!accountSelected}
          onClick={downloadCampaign}
        >
          <img
            src={downloadIcon}
            alt=""
            className="icon-btn"
            style={{ width: "23px", height: "23px" }}
          />
          エクセルに出力
        </Button>
      </div>
    </div>
  );
};

export default Download;
