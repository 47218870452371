import axios from "axios";

export const requestAuthentication = async (googleData) => {
    return await fetch(process.env.REACT_APP_SERVICE + '/users/login', {
        method: 'POST',
        body: JSON.stringify({
            token: googleData.credential,
        }),
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: "include"
    }).catch(_ => {
        console.log("Token is null")
    });
}

export const logout = async () => {
    return await fetch(process.env.REACT_APP_SERVICE + '/users/logout', {
        method: 'DELETE',
        credentials: "include"
    }).catch(e => {
        console.log(e)
    });
}

export const authenticate = async () => {
    return await fetch(process.env.REACT_APP_SERVICE + '/users/authenticate', {
        method: 'GET',
        credentials: "include"
    }).then((response) => {
        const { status } = response;
        if (status >= 500) throw Error
        if (status >= 400) {
            return null;
        } else {
            return response.json().then((body) => {
                return body.result
            })
        }
    }).catch(e => {
        console.log(e);
        return null;
    });
}

export function verifyLocalToken(token) {
    return axios.post(`${process.env.REACT_APP_SERVICE}/users/sessions`, null, {
        headers: {
            "Authorization": token
        }
    }).then((response) => {
        const { status } = response;
        if (status === 200) {
            return true;
        }
        if (status >= 400) {
            return false;
        }
    })
}

