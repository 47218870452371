import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "./index.css";
import logoOS from "../../assets/iretaro_logo.png";

import { useAuth } from "../../AuthProvider";

const Header = () => {
  const { onLogout } = useAuth();
  const { email } = useAuth();
  return (
    <div>
      <Navbar collapseOnSelect expand="lg" className="bg-white" variant="light">
        <Container>
          <Navbar.Brand>
          <img alt="logo" className="nav-logo" src={logoOS}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/download" className="nav-btn">
                DOWNLOAD
              </Nav.Link>
              <Nav.Link as={Link} to="/generate" className="nav-btn">
                GENERATE
              </Nav.Link>
            </Nav>
            {email && (
              <Nav>
                <Nav.Link>{email}</Nav.Link>    
                  <Button onClick={onLogout} className="btn-logout" variant="outline-dark">
                  Logout
                </Button>       
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
