import { connect } from 'react-redux';
import {
  dequeueNotification,
  hideNotification
} from '../actions/notificationActions';
import NotificationBar from "../../components/NotificationBar";

const mapStateToProps = state => ({
  notification: state.notificationReducer
});

const mapDispatchToProps = dispatch => ({
  dequeueNotification: () => dispatch(dequeueNotification()),
  hideNotification: () => dispatch(hideNotification())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationBar);
