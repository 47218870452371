import {request} from "./callAPI";
import {genRequestFinishAction, genRequestLoadingAction} from "../redux/actions/requestStatusActions";
import {
  enqueueGetDataFailedNotification, enqueueGetDataSuccessNotification
} from "../redux/actions/notificationActions";
import {saveAs} from 'file-saver'

export const requestAdAccount = () => {
  return async dispatch => {
    try {
      dispatch(genRequestLoadingAction());
      const result = await request.get("/ad_accounts")
      dispatch(genRequestFinishAction())
      return result.data
    } catch (error) {
      dispatch(genRequestFinishAction())
      return null
    }
  }
}

export const requestDownload = (idAccount) => {
  return async dispatch => {
    try {
      dispatch(genRequestLoadingAction());
      const result = await request
        .get(
          `/campaign_data/download/${idAccount}`,
          {
            responseType: "blob",
          }
        )
      dispatch(genRequestFinishAction())
      dispatch(enqueueGetDataSuccessNotification())
      const {headers} = result;
      const contentDisposition = headers["content-disposition"].split(";");
      const fileNamePart = contentDisposition
        .filter((_) => _.includes("filename"))[0]
        .trim();
      const filename = fileNamePart.split("=")[1].replace(/"/g, "");
      const file = new File([result.data], filename, {
        type: headers["content-type"]
      })
      saveAs(file)
    } catch (e) {
      dispatch(genRequestFinishAction())
      console.error(e)
      dispatch(enqueueGetDataFailedNotification())
    }
  }
}