import axios from "axios";
import {
  commonErrorHandler
} from "../redux/actions/requestStatusActions";

export const request = axios.create({
  baseURL: process.env.REACT_APP_SERVICE,
  crossDomain: true,
  withCredentials: true
})

request.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  commonErrorHandler(error.response.status)
  return Promise.reject(error);
});