import React, {useEffect} from "react";
import Alert from "react-bootstrap/Alert";
import "./index.css";
import {useAuth} from "../../AuthProvider";

function Login() {
    const {onLogin, loginFailed} = useAuth()

    useEffect(() => {
        const googleOnLoad = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback : onLogin
            })
            window.google.accounts.id.renderButton(
                document.getElementById('btn-login'), {
                    theme         : 'outline',
                    size          : 'large',
                    logo_alignment: 'left',
                    text          : 'Login with Google',
                    locale        : 'en-US'
                }
            )

            window.google.accounts.id.prompt()
        }

        if ( !!window.google ) setTimeout(googleOnLoad, 1000)
        else setTimeout(googleOnLoad, 1000)
    })

    return (
        <div className="login">
            <img className="login-image" src="iretaro_logo_278x278.png" alt="Onestar ad creation frontend"/>
            <div className="login-container">
                <div id='btn-login'/>
                {(loginFailed) ? (
                    <Alert variant="danger" className="errorMessage">
                        認証されていないメールアドレスのため、ログインできません
                    </Alert>
                ) : (
                    <p/>
                )}
            </div>
        </div>
    );
}

export default Login;
