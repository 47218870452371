import {
  NOTIFICATION_DEQUEUE,
  NOTIFICATION_ENQUEUE,
  NOTIFICATION_HIDE
} from '../actions/actionTypes';

export const notificationReducer = (
  state = {
    isOpen: false,
    queue: []
  },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_ENQUEUE:
      return {
        ...state,
        isOpen: true,
        queue: state.queue.concat(action.payload)
      };
    case NOTIFICATION_DEQUEUE:
      const slicedQueue = state.queue.slice(1);
      const isOpen = slicedQueue.length > 0;
      return {
        ...state,
        isOpen: isOpen,
        queue: slicedQueue
      };
    case NOTIFICATION_HIDE:
      return {
        ...state,
        isOpen: false
      };
    default:
      return state;
  }
};
